<template>
  <div class="class-content">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-scheduled-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-scheduled"
          type="button"
          role="tab"
          aria-controls="pills-scheduled"
          aria-selected="true"
        >
          Quiz
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-scheduled"
        role="tabpanel"
        aria-labelledby="pills-scheduled-tab"
      >
        <webinar-loader v-if="loader == true"></webinar-loader>
        <div class="table-responsive" v-else>
          <table class="table">
            <tr>
              <th>Sl No</th>
              <th>Exam</th>
              <th>Date</th>
              <th>Schedule</th>
              <th>Status</th>
            </tr>
            <tr v-for="(quiz, quizIndex) in quizzes" :key="quizIndex">
              <td>{{ quizIndex + quizCountFrom }}</td>
              <td>{{ quiz.title }}</td>
              <td>{{ quiz.date }}</td>
              <td>{{ quiz.schedule_title }}</td>
              <td>
                <router-link
                  v-if="quiz.status == 'LIVE'"
                  class="btn cta-mini"
                  :to="{ name: 'nestQuiz', params: { id: quiz.id } }"
                >
                  Attend
                </router-link>
                <span v-if="quiz.status == 'PUBLISHED'">
                  Not Started
                </span>
                <router-link
                  v-if="quiz.status == 'ENDED'"
                  class="btn cta-mini"
                  :to="{ name: 'quizResult', params: { id: quiz.id } }"
                >
                  View Result
                </router-link>
              </td>
            </tr>
          </table>
        </div>

        <div class="empty" v-if="quizzes.length == 0 && loader == false">
          No quiz found
        </div>

        <div class="paging-wrapper">
          <v-pagination
            v-model="quizzesPage"
            :pages="quizTotalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="quizChangeHandler"
            v-if="totalExams > 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorLog from "@/errorLog";
import "vue3-pagination/dist/vue3-pagination.css";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import VPagination from "vue3-pagination";
import QuizService from "@/services/QuizService";

export default {
  name: "QuizListTab",
  components: {
    WebinarLoader,
    VPagination
  },
  data() {
    return {
      quizzes: [],
      loader: true,
      quizzesPage: 1,
      quizTotalPages: 0,
      totalExams: 0,
      quizCountFrom: 1
    };
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isNestMember"]);
      return this.$store.getters["privilege/isNestMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isNestMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "profile" });
      }
    }
  },
  created() {
    this.getQuizzes();
  },
  methods: {
    async getQuizzes() {
      await QuizService.getQuizzes({ page: this.quizzesPage })
        .then(result => {
          this.quizzes = result.data.quizzes.data;
          this.quizTotalPages = result.data.quizzes.last_page;
          this.totalExams = result.data.quizzes.total;
          this.quizCountFrom = result.data.quizzes.from;
          this.loader = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },

    quizChangeHandler(selectedPage) {
      this.quizzesPage = selectedPage;
      this.getQuizzes();
    },

    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    }
  }
};
</script>

<style lang="scss">
@import "@/style/class-tab.scss";
</style>
