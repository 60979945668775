import Http from "@/services/Http";
export default {
  getQuizzes(data) {
    return Http().post("nest/quizzes", data);
  },
  getQuestions(data) {
    return Http().post("nest/quiz/questions", data);
  },
  submitQuiz(data) {
    return Http().post("nest/quiz/submit", data);
  },
  getQuizResult(data) {
    return Http().post("nest/quiz/result", data);
  },
  getExamResult(data) {
    return Http().post("exam/get/result", data);
  },
  getTodayExams(data) {
    return Http().post("exam/today", data);
  }
};
